import { User } from 'Types/user';

import usePermission, { checkForPermission } from './usePermission';

const tradePermissionName = 'trade';

export const checkForTradePermission = (user: User): boolean => checkForPermission(user, tradePermissionName);

const useTradePermission = (): boolean => usePermission(tradePermissionName);

export default useTradePermission;
