import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { snowplowUserGlobalContext } from 'Services/analytics/snowplowGlobalContext/userContext';
import { snowplowVehicleListGlobalContext } from 'Services/analytics/snowplowGlobalContext/vehicleListContext';
import { trackSnowplowPageView } from 'Services/snowplow';
import { useFilters } from 'Stores/FilterStore/VehicleListFilter/VehicleListFilterStore';
import { useListType } from 'Stores/ListStore/ListStore';
import { useCurrentFilter } from 'Stores/SavedFiltersStore/SavedFiltersStore';
import { User } from 'Types/user';
import useIsAuth from 'Utilities/hooks/useIsAuth';

interface AnalyticsGlobalContextProps {
	user: User;
}

export const AnalyticsGlobalContext: React.FC<AnalyticsGlobalContextProps> = ({ user }) => {
	const listType = useListType();
	const { pathname } = useRouter();
	const filters = useFilters();
	const isAuth = useIsAuth();
	const currentFilter = useCurrentFilter();

	useEffect(() => {
		snowplowUserGlobalContext({ isAuth, user });
	}, [isAuth, user]);

	useEffect(() => {
		snowplowVehicleListGlobalContext({ currentFilter, filters, listType, pathname });
	}, [filters, listType, pathname, currentFilter]);

	useEffect(() => {
		trackSnowplowPageView();
	}, []);

	return null;
};
