export const VEHICLE_LIST_FILTER_NAME = Object.freeze({
	AGE_FROM: 'ageFrom',
	AGE_TO: 'ageTo',
	DISPLAY_PRICE_FROM: 'displayPriceFrom',
	DISPLAY_PRICE_TO: 'displayPriceTo',
	EXCLUDE_VEHICLES_ON_FINANCE: 'excludeVehiclesOnFinance',
	EXTERIOR_GRADE: 'exteriorGrade',
	FUEL: 'fuel',
	INCLUDE_SOLD: 'includeSold',
	INCLUDE_UNDER_OFFER: 'includeUnderOffer',
	IS_AVAILABLE_FOR_COLLECTION: 'isAvailableForCollection',
	LIST_TYPE: 'listType',
	MAKE: 'make',
	MAX_DISTANCE: 'maxDistance',
	MILEAGE_FROM: 'mileageFrom',
	MILEAGE_TO: 'mileageTo',
	MODEL: 'model',
	ORDER: 'order',
	PAGE: 'page',
	PREVIOUS_KEEPERS_COUNT_FROM: 'previousKeepersCountFrom',
	PREVIOUS_KEEPERS_COUNT_TO: 'previousKeepersCountTo',
	SELECTED_VEHICLE: 'selectedVehicle',
	SELLER_TYPE: 'sellerType',
	SERVICE_HISTORY: 'serviceHistory',
	SORT: 'sort',
	TRADE: 'trade',
	TRANSMISSION: 'transmission',
	VEHICLE_CLASS: 'vehicleClass',
});

export const VEHICLE_LIST_AVAILABLE_FILTERS = Object.freeze(Object.values(VEHICLE_LIST_FILTER_NAME));

export const VEHICLE_LIST_TYPE_FILTERS = Object.freeze([
	VEHICLE_LIST_FILTER_NAME.INCLUDE_SOLD,
	VEHICLE_LIST_FILTER_NAME.INCLUDE_UNDER_OFFER,
	VEHICLE_LIST_FILTER_NAME.LIST_TYPE,
]);

export const VEHICLE_LIST_FILTERS_EXCLUDED_FROM_COUNTER = Object.freeze([VEHICLE_LIST_FILTER_NAME.SELECTED_VEHICLE]);

export const VEHICLE_CLASSES = Object.freeze({
	ALL: 'all',
	CAR: 'Car',
	VAN: 'LCV',
});

export const VEHICLE_VALUES_TYPES = Object.freeze({
	ARRAY: 'array',
	BOOL: 'boolean',
	NUMBER: 'number',
	STRING: 'string',
});

export const SERVICE_HISTORY = {
	FULL: 'full',
	NONE: 'no',
	NOT_YET_DUE: 'not_applicable',
	PARTIAL: 'partial',
} as const;

const ALL = 'all';

export const SERVICE_HISTORY_TYPES = [
	ALL,
	SERVICE_HISTORY.FULL,
	SERVICE_HISTORY.PARTIAL,
	SERVICE_HISTORY.NONE,
	SERVICE_HISTORY.NOT_YET_DUE,
];

export const SELLER_TYPES = {
	ALL: 'all',
	PRIVATE: 'private',
	TRADE: 'trade',
} as const;

export const VEHICLE_LIST_FILTERS_ID = {
	[VEHICLE_LIST_FILTER_NAME.EXTERIOR_GRADE]: 'exteriorGrades',
	[VEHICLE_LIST_FILTER_NAME.SERVICE_HISTORY]: 'serviceHistories',
	[VEHICLE_LIST_FILTER_NAME.FUEL]: 'fuels',
	[VEHICLE_LIST_FILTER_NAME.TRANSMISSION]: 'transmissions',
	[VEHICLE_LIST_FILTER_NAME.SELLER_TYPE]: 'sellerTypes',
};

export const SELLER_TYPE_BADGE_TEXT = 'BETA';
