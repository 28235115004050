import dayjs from 'dayjs';
import T from 'i18n-react';
import startCase from 'lodash/startCase';

import texts from '../components/texts.json';

import { DEFAULT_LANGUAGE } from './consts';
import { isArray, isFunction, isNil, isSSR } from './helpers';
import { notYetForSaleVehicleStates, SLUGS, UNSOLD_CATEGORY } from './vehicleState';

T.setTexts(texts);

export const formatText = (text) => {
	// eslint-disable-next-line no-restricted-globals
	if ((typeof text === 'string' || typeof text === 'number') && !isNaN(text)) {
		return text.toLocaleString();
	}
	if (typeof text === 'string') {
		let t = text
			.toLowerCase()
			.replace(/_/g, ' ')
			.replace(/ and /g, ' & ')
			.replace(/\.\s+([a-z])|^\w/g, (c) => c.toUpperCase());
		const reg = /<\*(.*?)\*>/g;
		let match;
		// eslint-disable-next-line no-cond-assign
		while ((match = reg.exec(t))) {
			t = t.replace(match[0], match[1].toUpperCase());
		}

		return t;
	}
	if (typeof text === 'boolean') {
		return text ? 'Yes' : 'No';
	}
	if (typeof text === 'function') {
		return text();
	}
	if (text == null) {
		return '–';
	}

	return text;
};

export const toUpperCaseFirst = (str) => str.replace(/^\w/, (c) => c.toUpperCase());

export const toTitleCase = (str) =>
	str && str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

export const formatMake = (make = '') => {
	if (make.toLowerCase() === 'kia') {
		return 'Kia';
	}

	if (make.length <= 3) {
		return make.toUpperCase();
	}

	if (make.includes(' cars')) {
		return make.split(' ')[0];
	}

	if (['seat', 'mini', 'skoda'].includes(make.toLowerCase())) {
		return make.toUpperCase();
	}

	return toTitleCase(make.split('-')[0]);
};

export const formatVehicleName = ({ make = '', model = '' } = {}) => {
	const fallback = formatMake(make) || model;
	return make && model ? `${formatMake(make)} ${model}` : fallback;
};

export const slugify = (text) => {
	let slug = text;
	if (typeof text === 'function') {
		slug = text() || '';
	} else if (text == null) {
		slug = '';
	}

	return slug
		.toString()
		.replace(/ /g, '_')
		.replace(/,/g, '')
		.replace(/and/g, '')
		.replace(/&/g, '')
		.replace(/__/g, '_')
		.toLowerCase();
};

export const formatDate = (date, format = 'DD/MM/YYYY') => {
	const dayjsDate = dayjs(date || null);
	return dayjsDate.isValid() ? dayjsDate.format(format) : '–';
};

export const formatInteriorType = (interiorTypeValue) => {
	if (!interiorTypeValue) {
		return '';
	}

	switch (interiorTypeValue) {
		case 'Leather':
			return 'full leather';
		default:
			return interiorTypeValue?.toLowerCase?.();
	}
};

export const formatImageURLWithRect = (meta) => {
	if (!meta || !Number.isInteger(parseInt(meta.x))) {
		return '';
	}

	return `${meta.x},${meta.y},${meta.width},${meta.height}`;
};

export const formatImageURLMeta = (meta) => {
	const params = [];

	if (!meta) {
		return params;
	}

	if (Number.isInteger(parseInt(meta.x))) {
		params.push(`rect=${encodeURIComponent(formatImageURLWithRect(meta))}`);
	}

	const rotate = parseInt(meta.rotate);

	if (rotate) {
		params.push(`or=${meta.rotate}`);
	}

	return params;
};

export const formatImageURLWithMeta = (img, imgixParams = {}) => {
	const {
		/* eslint-disable @typescript-eslint/no-unused-vars */
		createdAt,
		damageMeta,
		enquiryId,
		id,
		kind,
		meta,
		source,
		url,
		...image
	} = img;
	/* eslint-enable */

	// For all images that expire or generated as blobs (from expired images) we don't want to
	// format the image url. This will break the image rendering
	if (url.includes('expires=') || url.startsWith('blob:')) {
		return url;
	}

	const getImageDPR = () => (!isSSR() && window.devicePixelRatio > 1 ? window.devicePixelRatio : 1);

	const params = {
		auto: 'format',
		dpr: getImageDPR(),
		fit: 'max',
		h: 1600,
		q: 60,
		w: 1600,
		...image,
		...imgixParams,
	};

	const queryParams = Object.entries(params)
		.filter(([, val]) => !isNil(val))
		.map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
		.concat(formatImageURLMeta(meta))
		.join('&');

	return `${url}?${queryParams}`;
};

export const formatState = ({ category, name, shouldUseProcessingStatus, slug }) => {
	if (category === 'sold') {
		return T.translate('slugs.sold');
	}

	if (notYetForSaleVehicleStates.includes(slug)) {
		return T.translate('slugs.not_yet_for_sale');
	}

	if (slug === SLUGS.forSale) {
		return T.translate('slugs.for_sale_v2');
	}

	if (slug === SLUGS.saleActive) {
		// At the end of sale, the BE may have a slight delay updating enquiry statuses.
		// We temporarily set the status to processing whilst waiting for the BE to update the vehicle's state accordingly.
		return shouldUseProcessingStatus ? T.translate('slugs.processing') : T.translate('slugs.sale_active_v2');
	}

	if (slug === UNSOLD_CATEGORY.UNSOLD_BELOW_RESERVE_BID) {
		return T.translate('slugs.under_offer');
	}

	return name;
};

export const formatSlugFromMake = (make = '') => (make === null ? '' : make.toLowerCase().replace(/\s/g, '-'));

/**
 * Checks if the vehicle make is supposed to be an acronym.
 * If true then capitalise if not then make title case.
 * @param {String} make Vehicle make
 */
export const makeWithStartCase = (make) =>
	['bmw', 'skoda', 'seat'].includes(make) ? make.toUpperCase() : startCase(make);

/**
 * Format the vehicle make from a slug.
 * @param {String} slug Slug to be formatted.
 * @param {Boolean} withStartCase Choose whether to capitalise the first letter.
 */
export const formatMakeFromSlug = (slug = '', withStartCase = false) => {
	if (slug === null) {
		return '';
	}

	return slug
		.split('-')
		.map((word) => (withStartCase ? makeWithStartCase(word) : word))
		.join(' ');
};

export const formatAvailibilityDate = (date) => {
	if (!date) {
		return undefined;
	}

	return dayjs(date).format('Do MMMM YYYY');
};

export const getYearFromDate = (date) => dayjs(date).year();

export const prettyPostcode = (postCodeStr) => {
	const newPostCodeStr = (postCodeStr || '').replace(/ /g, '');
	const last3 = newPostCodeStr.slice(newPostCodeStr.length - 3);
	let reqStr;
	try {
		reqStr = new RegExp(`${last3}$`);
	} catch {
		reqStr = last3;
	}
	return newPostCodeStr.replace(reqStr, ` ${last3}`);
};

export const formatTransmission = (t, middleware) => {
	t = t || '';
	let result = t;

	switch (t.toLowerCase()) {
		case 'semi-automatic': {
			result = 'S/A';
			break;
		}
		default:
			break;
	}

	if (isFunction(middleware)) {
		result = middleware(t);
	}

	return result;
};

export const autoShorthand = (t) => (t === 'Automatic' ? 'Auto' : t);

export const formatBidValueToNumber = (value = '') => parseFloat(value.replace(/,/g, ''));

export const formatVehiclePrice = (label, value, showVAT) =>
	`${T.translate(`vehicleDetails.${label}`)}: £${formatText(value)} ${showVAT ? '+VAT' : ''}`;

export const normalizeEmptySpaces = (str) => str?.trim?.().replace(/\s+/g, ' ');

export const removeFromString = (str, arr) => {
	if (isNil(str)) {
		return '';
	}
	if (!isArray(arr) || arr.length === 0) {
		return str;
	}
	/* Remove words included in the array */
	const regex = new RegExp(`\\b${arr.join('|')}\\b`, 'gi');
	return str.replace(regex, '').trim();
};

export const formatSalesReportDownloadName = (url = '') => {
	if (url) {
		const { pathname } = new URL(url);
		return pathname.replace('/', '');
	}
	return `Motorway_Sales_Report_${new Date().toLocaleDateString()}.csv`;
};

export const gbpCurrencyFormatter = (options = {}) =>
	new Intl.NumberFormat(DEFAULT_LANGUAGE, {
		currency: 'GBP',
		style: 'currency',
		...options,
	});

export const DATE_FORMATS = {
	DAY_MONTH_YEAR: 'D MMM YYYY',
};

export const formatCount = (count) => {
	if (count === undefined) {
		return '';
	}

	if (count < 1000) {
		return count.toString();
	}

	const suffix = 'k';
	const scaled = count / 1000;
	const rounded = Math.floor(scaled * 10) / 10;
	const formatted = rounded.toLocaleString();

	return formatted.endsWith('.0') ? formatted.slice(0, -2) + suffix : formatted + suffix;
};

export const UPPER_CASED_BODY_TYPES = ['MPV', 'SUV'];

export const formatBodyType = (bodyType = '') => {
	if (UPPER_CASED_BODY_TYPES.includes(bodyType?.toUpperCase())) {
		return bodyType?.toUpperCase();
	}
	return formatText(bodyType);
};
