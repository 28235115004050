export default [
	{
		id: 1,
		makeId: 7,
		name: 'A3',
		namePretty: 'A3',
		total: 0,
	},
	{
		id: 2,
		makeId: 7,
		name: 'A4',
		namePretty: 'A4',
		total: 0,
	},
	{
		id: 22,
		makeId: 7,
		name: 'Other',
		namePretty: 'Other Audi',
		total: 0,
	},
	{
		id: 3,
		makeId: 20,
		name: 'M1',
		namePretty: 'M1',
		total: 0,
	},
	{
		id: 33,
		makeId: 20,
		name: 'Other',
		namePretty: 'Other BMW',
		total: 0,
	},
	{
		id: 4,
		makeId: 12,
		name: '500',
		namePretty: '500',
		total: 0,
	},
	{
		id: 5,
		makeId: 17,
		name: 'TIGUAN',
		namePretty: 'Tiguan',
		total: 0,
	},
	{
		id: 6,
		makeId: 17,
		name: 'GOLF',
		namePretty: 'Golf',
		total: 0,
	},
	{
		id: 7,
		makeId: 17,
		name: 'UP',
		namePretty: 'Up',
		total: 0,
	},
	{
		id: 77,
		makeId: 17,
		name: 'Other',
		namePretty: 'Other Volkswagen',
		total: 0,
	},
	{
		id: 583,
		makeId: 10,
		name: 'CIVIC',
		namePretty: 'Civic',
		total: 0,
	},
];
