import { memo, PropsWithChildren, useRef } from 'react';
import { ReactNodeLike } from 'prop-types';
import { OptimizelyProvider } from '@optimizely/react-sdk';

import OptimizelyNotifications from 'Components/OptimizelyNotifications/OptimizelyNotifications';
import { ConfigDatafile, getUserAttributesPayload, optimizelyInstanceFactory } from 'Services/optimizely/optimizely';
import { User } from 'Types/user';

interface OptimizelyInitialiserProps {
	children: NonNullable<ReactNodeLike>;
	optimizelyDatafile: ConfigDatafile;
	user: User;
}

export const OptimizelyInitialiserComponent: React.FC<PropsWithChildren<OptimizelyInitialiserProps>> = ({
	children,
	optimizelyDatafile,
	user,
}) => {
	const datafileRef = useRef(optimizelyDatafile ?? null);

	if (!user) {
		return <>{children}</>;
	}

	const optimizelyInstance = optimizelyInstanceFactory(datafileRef.current);
	const optimizelyUser = {
		attributes: {
			...getUserAttributesPayload({
				dealerContactId: user?.id,
				dealershipId: user?.dealerId,
			}),
		},
		id: user ? String(user.id) : null,
	};

	return (
		<OptimizelyProvider optimizely={optimizelyInstance} user={optimizelyUser}>
			<OptimizelyNotifications />
			{children}
		</OptimizelyProvider>
	);
};

export const OptimizelyInitialiser = memo(OptimizelyInitialiserComponent);
