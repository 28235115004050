import get from 'lodash/get';
import set from 'lodash/set';
import { DateTime } from 'luxon';
import qs from 'qs';

import { logger } from 'Services/logger/logger';
import { ListType } from 'Types/listType';
import { LIST_TYPES } from 'Utilities/consts';

import { GetVehicleListRequestQuery } from './api/vehicles.types';
import {
	ALLOWED_STATES_FOR_MAKE_MODEL_COUNT,
	INVERSE_FEATURE_TOGGLES,
	QUERY_STRING_OPTIONS,
} from './dataProvider.const';
import { BEToFEFeatureFlagsMapParam, FeatureFlags, ScopedFeatureFlags } from './dataProvider.types';
import { resolveExclusiveToDealer } from './services.helpers';

// We want to limit how old the sold vehicle entries we're fetching are
export const generateSoldDateTimestamp = (): Nullable<string> => DateTime.now().minus({ years: 1 }).toISODate();

export const getMakeModelStateFilter = (listType?: ListType): string[] => {
	if (!listType || !ALLOWED_STATES_FOR_MAKE_MODEL_COUNT[listType]) {
		return [];
	}
	return ALLOWED_STATES_FOR_MAKE_MODEL_COUNT[listType];
};

export const getMakeModelCountQuery = (params: GetVehicleListRequestQuery): string => {
	const {
		ageFrom,
		ageTo,
		bodyCategory,
		displayPriceFrom,
		displayPriceTo,
		distanceFromDealerId,
		excludeVehiclesOnFinance,
		exteriorGrade,
		fuel,
		isAvailableForCollection,
		listType,
		maxDistance,
		mileageFrom,
		mileageTo,
		previousKeepersCountFrom,
		previousKeepersCountTo,
		serviceHistory,
		transmission,
		vehicleClass,
	} = params;

	const isShortlist = listType === LIST_TYPES.shortlist;

	const state = getMakeModelStateFilter(listType);

	if (!state) {
		return '';
	}

	const apiParams = {
		ageFrom,
		ageTo,
		bodyCategory,
		countBy: ['makes', 'models'],
		displayPriceFrom,
		displayPriceTo,
		distanceFromDealerId,
		excludeVehiclesOnFinance,
		exclusiveToDealer: resolveExclusiveToDealer({ trade: Boolean(params?.trade) }),
		fuel,
		...(isShortlist ? { forSale: true } : {}),
		grade: exteriorGrade,
		isAvailableForCollection,
		maxDistance,
		mileageFrom,
		mileageTo,
		previousKeepersCountFrom,
		previousKeepersCountTo,
		serviceHistory,
		shortlisted: isShortlist,
		state,
		transmission,
		vehicleClass,
	};

	return `${qs.stringify(apiParams, QUERY_STRING_OPTIONS)}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const redact = (obj: Record<any, any>, ...keys: string[]): Record<any, any> => {
	const logs = { ...obj };

	keys.forEach((key) => {
		if (get(logs, key)) {
			set(logs, key, '[REDACTED]');
		}
	});

	return logs;
};

export const extractFEFeatureFlags = (
	featureFlags: ScopedFeatureFlags,
	BEToFEFeatureFlagsMap: BEToFEFeatureFlagsMapParam,
): FeatureFlags => {
	const mappingEntries = Object.entries(BEToFEFeatureFlagsMap);
	try {
		return mappingEntries.reduce((acc, [beToggleName, { feToggleName, scope }]) => {
			// Scope determines whether we take dealership toggle value or user toggle value
			const scopedFlagValue = featureFlags[scope]?.[beToggleName];
			// Some toggles fetched from LD as true when we want to set them to false on the FE and vice versa
			const shouldInverseBEValue = INVERSE_FEATURE_TOGGLES.includes(feToggleName);

			acc[feToggleName] = shouldInverseBEValue ? !scopedFlagValue : Boolean(scopedFlagValue);
			return acc;
		}, {} as FeatureFlags);
	} catch (e) {
		const error = e instanceof Error ? e : new Error('There was an error extracting feature flags');
		logger.error({ error, message: error.message, scope: 'dataProvider.helpers extractFEFeatureFlags' });
		return {};
	}
};
