import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import qs from 'qs';

import { stringBooleanToBoolean } from './helpers';

// eslint-disable-next-line import/prefer-default-export
export const castToType = ({ type, value }) => {
	switch (type) {
		case 'number': {
			const casted = parseFloat(value);
			return Number.isNaN(casted) ? value : casted;
		}

		case 'array':
			return Array.isArray(value) ? value : [value];

		case 'boolean': {
			if (value === 'true' || value === 'false') {
				return stringBooleanToBoolean(value);
			}

			return Boolean(value);
		}
		default:
			return value;
	}
};

export const isMultidimensionalArray = (array) => Array.isArray(array) && array.filter(Array.isArray).length;

export const is2DArrayEmpty = (array) => compact(flatten(array)).length === 0;

export const parseQueryParams = (queryParams) => qs.parse(queryParams);

export const getQueryParamsFromRequest = (req = {}) => parseQueryParams(req?.query);

export const isJSON = (str) => {
	try {
		const obj = JSON.parse(str);
		return !!obj && typeof obj === 'object' && obj !== null;
	} catch (err) {
		return false;
	}
};

export const sleep = (ms = 3000) =>
	new Promise((resolve) => {
		setTimeout(resolve, ms);
	});

export const isMotorwayEmail = (email = '') => /@motorway.co.uk\s*$/.test(email);
