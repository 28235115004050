import { useEffect } from 'react';
import type { SlideUpMessage as SlideUpMessageType } from '@braze/web-sdk';

import { useBrazeProvider } from 'Context/BrazeContext/BrazeContext';
import { brazeAuth } from 'Services/api/braze';
import { logger } from 'Services/logger/logger';

import { BrazeSDKAuthError } from './braze.types';
import { reportBrazeSdkAuthError } from './brazeErrorHandling';

export const sdkAuthenticationSubscriber = async (error: BrazeSDKAuthError, signedInUserId: string): Promise<void> => {
	const { setSdkAuthenticationSignature } = await import('Services/braze/brazeExports');
	const { userId } = error;

	reportBrazeSdkAuthError(error);

	if (userId === signedInUserId) {
		const { signedBrazeSignature } = await brazeAuth();
		setSdkAuthenticationSignature(signedBrazeSignature);
	}
};

type UseBrazePayload<T> =
	| { eventFn?: (args: T) => Promise<void>; eventFnArgs: T }
	| {
			eventFn?: () => Promise<void>;
	  };

export const useBraze = <T,>({ eventFn, ...args }: UseBrazePayload<T>): void => {
	const { isBrazeSDKInitialised } = useBrazeProvider();

	// @ts-expect-error - TS does not understand the type decoupling when eventFnArgs exists or not
	const { eventFnArgs } = args || {};

	useEffect(() => {
		if (isBrazeSDKInitialised && eventFn) {
			eventFn(eventFnArgs).catch(({ message }) => logger.warn({ message, scope: eventFn.name }));
		}
	}, [isBrazeSDKInitialised, eventFn, eventFnArgs]);
};

export const modifySlideUpMessage = async ({
	modifications,
	originalMessage,
}: {
	modifications: Partial<SlideUpMessageType>;
	originalMessage: SlideUpMessageType;
}): Promise<SlideUpMessageType> => {
	const { SlideUpMessage } = await import('Services/braze/brazeExports');

	const modifiedMessage = {
		animateIn: modifications.animateIn ?? originalMessage.animateIn,
		animateOut: modifications.animateOut ?? originalMessage.animateOut,
		backgroundColor: modifications.backgroundColor ?? originalMessage.backgroundColor,
		clickAction: modifications.clickAction ?? originalMessage.clickAction,
		closeButtonColor: modifications.closeButtonColor ?? originalMessage.closeButtonColor,
		css: modifications.css ?? originalMessage.css,
		dismissType: modifications.dismissType ?? originalMessage.dismissType,
		duration: modifications.duration ?? originalMessage.duration,
		extras: modifications.extras ?? originalMessage.extras,
		htmlId: modifications.htmlId ?? originalMessage.htmlId,
		icon: modifications.icon ?? originalMessage.icon,
		iconBackgroundColor: modifications.iconBackgroundColor ?? originalMessage.iconBackgroundColor,
		iconColor: modifications.iconColor ?? originalMessage.iconColor,
		imageUrl: modifications.imageUrl ?? originalMessage.imageUrl,
		message: (modifications.message ?? originalMessage.message) || '',
		messageAlignment: modifications.messageAlignment ?? originalMessage.messageAlignment,
		openTarget: modifications.openTarget ?? originalMessage.openTarget,
		slideFrom: modifications.slideFrom ?? originalMessage.slideFrom,
		textColor: modifications.textColor ?? originalMessage.textColor,
		triggerId: modifications.triggerId ?? originalMessage.triggerId,
		uri: modifications.uri ?? originalMessage.uri,
	};

	const {
		animateIn,
		animateOut,
		backgroundColor,
		clickAction,
		closeButtonColor,
		css,
		dismissType,
		duration,
		extras,
		htmlId,
		icon,
		iconBackgroundColor,
		iconColor,
		imageUrl,
		message,
		messageAlignment,
		openTarget,
		slideFrom,
		textColor,
		triggerId,
		uri,
	} = modifiedMessage;

	const modifiedSlideUpMessage = new SlideUpMessage(
		message,
		messageAlignment,
		slideFrom,
		extras,
		triggerId,
		clickAction,
		uri,
		openTarget,
		dismissType,
		duration,
		icon,
		imageUrl,
		iconColor,
		iconBackgroundColor,
		backgroundColor,
		textColor,
		closeButtonColor,
		animateIn,
		animateOut,
		htmlId,
		css,
	);

	return modifiedSlideUpMessage;
};
