import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { BIDDING } = ANALYTICS_CATEGORIES;

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

export const triggerMakeOfferButtonClickedEvent = (): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: BIDDING,
				name: 'dlr_make_offer_button_clicked',
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: 'Make offer button clicked',
				category: BIDDING,
				isLegacyEvent: false,
				label: '',
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
