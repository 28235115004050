import pick from 'lodash/pick';
import qs from 'qs';

import { FEATURES } from 'Context/FeaturesContext/FeaturesContextConsts';
import { stringBooleanToBoolean } from 'Utilities/helpers';

import {
	SELLER_TYPES,
	VEHICLE_LIST_FILTER_NAME,
	VEHICLE_LIST_TYPE_FILTERS,
} from '../components/VehicleList/VehicleListFilters.consts';
import { allowedVehicleStatesByFilter } from '../utilities/vehicleState';

import { logger } from './logger/logger';

export const resolveExclusiveToDealer = ({ trade }) => {
	if (trade) {
		return 'Trade';
	}

	return 'None';
};

export const resolveParams = (query) => (query ? `?${qs.stringify(query, { indices: false })}` : '');

export const resolveVehicleListParams = (query) => {
	const { filterQuery, ...presentationQuery } = query;
	if (filterQuery && presentationQuery) {
		return `${filterQuery}&${qs.stringify(presentationQuery, { indices: false })}`;
	}
	if (presentationQuery) {
		return `${qs.stringify(presentationQuery, { addQueryPrefix: true, indices: false })}`;
	}
	return '';
};

export const resolveStatesToFetch = (params) => {
	try {
		const listTypeFilters = pick(params, VEHICLE_LIST_TYPE_FILTERS);

		const getApplicableStates = ({ states, value }) =>
			[].concat(value).reduce((acc, v) => {
				const vehicleStatesConfigValue = allowedVehicleStatesByFilter?.[v];
				if (!vehicleStatesConfigValue) {
					throw new Error('Invalid Buying Format', v);
				}
				return [...acc, ...vehicleStatesConfigValue];
			}, states);

		const statesToFetch = Object.entries(listTypeFilters).reduce((states, filter) => {
			const [filterName, value] = filter;

			switch (filterName) {
				case VEHICLE_LIST_FILTER_NAME.LIST_TYPE: {
					return getApplicableStates({ states, value });
				}
				default: {
					// this may need to be extened to check other types in future
					// currently it only supports boolean as under this case are
					// both `includeSold` and `includeUnderOffer` filters
					if (!value) {
						return states;
					}

					return [...states, ...allowedVehicleStatesByFilter[filterName]];
				}
			}
		}, []);
		return statesToFetch;
	} catch (e) {
		logger.warn({
			message: e?.message || e,
			scope: 'resolveStatesToFetch',
		});
		return undefined;
	}
};

export const resolveIsTradeToFetch = (features, sellerType) => {
	try {
		const isSellerTypeFilterEnabled = stringBooleanToBoolean(features[FEATURES.sellerTypeFilter]);
		const isSellerTypeAsString = sellerType && typeof sellerType === 'string';
		const shouldAttachIsTradeFlag = isSellerTypeFilterEnabled && isSellerTypeAsString;
		const isTradeSellerType = sellerType === SELLER_TYPES.TRADE;
		return shouldAttachIsTradeFlag ? isTradeSellerType : undefined;
	} catch (e) {
		logger.warn({
			message: e?.message || e,
			scope: 'resolveIsTradeToFetch',
		});
		return undefined;
	}
};
