import React from 'react';
import PropTypes from 'prop-types';
import T from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';

import texts from '../texts.json';

import styles from './NotFound.module.scss';

T.setTexts(texts);

const NotFound = ({ buttonText, message, onClickButton }) => (
	<div className={styles.notFoundArea}>
		<section className={styles.notFound}>
			<h1 className="mw-title-title5">{message}</h1>
			<div className={styles.button}>
				<Button
					fullWidth
					label={buttonText || String(T.translate('notFoundPage.allVehicles'))}
					onClick={onClickButton}
				/>
			</div>
		</section>
	</div>
);

NotFound.defaultProps = {
	buttonText: undefined,
	message: '',
	onClickButton: undefined,
};

NotFound.propTypes = {
	buttonText: PropTypes.string,
	message: PropTypes.string,
	onClickButton: PropTypes.func,
};

export default NotFound;
