import { User } from 'Types/user';

import { SNOWPLOW_EVENTS } from '../analytics.const';

import { createConditionalGlobalContext, getGlobalContext } from './globalContext.helpers';

const { USER_DEALER_CONTACT } = SNOWPLOW_EVENTS;

interface SnowplowUserGlobalContextProps {
	isAuth: boolean;
	user: User;
}

export const snowplowUserGlobalContext = ({ isAuth, user }: SnowplowUserGlobalContextProps): void => {
	const conditionalFilter = () => isAuth;

	const userGlobalContext = getGlobalContext({
		data: {
			dealer_site_name: user?.dealerName,
			logged_in: isAuth,
			user_dealer_contact_id: user?.id,
			user_dealer_site_id: user?.dealerId,
		},
		schemaEvent: USER_DEALER_CONTACT,
	});

	createConditionalGlobalContext({
		conditionalFilter,
		previousGlobalContextDataKey: 'user',
		updatedGlobalContextData: userGlobalContext,
	});
};
