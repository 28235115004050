import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

const { HOMEPAGE, NAVIGATION } = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerNavigationBidsClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_bno_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationActiveBidsClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_bno_active_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationDidntWinBidsClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_bno_didnt_win_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationUnderOfferBidsClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_bno_under_offer_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationPurchasesClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_purchases_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationCompletedPurchasesClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_purchases_completed_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationCancelledPurchasesClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_purchases_cancelled_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationMotorwayPayClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_motorway_pay_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationMotorwayMoveClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_motorway_move_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationMotorwayTradeClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_motorway_trade_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationTradeClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_trade_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationAvatarClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_user_avatar_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationBillingClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_billing_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationWalletClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_wallet_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationHelpCenterClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_help_center_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationPricingClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_pricing_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationSignOutClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_user_sign_out_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationMarketplaceClickedEvent = ({ isSaleSlug }: { isSaleSlug?: boolean }): void => {
	const label = isSaleSlug ? 'live-sale' : 'buy-it-now';

	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		customData: { label },
		name: `dlr_marketplace_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationHowItWorksClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_guest_how_it_works_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationLogoClickedEvent = (isGuest: boolean): void => {
	const userType = isGuest ? 'guest' : 'user';

	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: `dlr_${userType}_logo_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerNavigationInsightsClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: NAVIGATION,
		name: 'dlr_insights_global_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerAccountSettingsLinkClickedEvent = (): void =>
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: NAVIGATION,
			name: 'dlr_sitewide_nav_account_settings_link_clicked',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});

export const triggerSignUpLinkClicked = (): void => {
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: NAVIGATION,
			customData: { label: 'Guest menu' },
			name: 'dlr_sign_up_link_clicked',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});

	logAnalyticsEvent({
		eventPayload: {
			action: 'Sign up link clicked',
			category: NAVIGATION,
			isLegacyEvent: false,
			label: 'Guest menu',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerSignInLinkClicked = (): void => {
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: NAVIGATION,
			customData: { label: 'Guest menu' },
			name: 'dlr_sign_in_link_clicked',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});

	logAnalyticsEvent({
		eventPayload: {
			action: 'Sign in link clicked',
			category: NAVIGATION,
			isLegacyEvent: false,
			label: 'Guest menu',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerSignUpNowLinkClicked = (buttonLocation: 'page_top' | 'page_bottom'): void => {
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: HOMEPAGE,
			customData: { button_label: 'Sign up', label: buttonLocation, url: '/signup' },
			name: 'sign_up_button_clicked',
			schemaName: SNOWPLOW_EVENTS.CTA_CLICKED.SCHEMA,
			version: SNOWPLOW_EVENTS.CTA_CLICKED.VERSION,
		}),
	});
};
