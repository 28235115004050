export const DEFAULT_DATADOG_MASKED_EVENT_NAME = '(redacted due to masking)';

export const sessionReplayMaskLevel = {
	allow: 'allow',
	hidden: 'hidden',
	ignore: 'ignore',
	mask: 'mask',
	maskUserInput: 'mask-user-input',
} as const;

// List of events we want to mask
export const MASKED_EVENT_VALUES = {
	account: {
		maximumBid: 'maximum bid',
		savings: 'savings',
		value: 'product price',
	},
	dealer: { name: 'dealership name', postcode: 'dealer postcode' },
	delivery: {
		address: 'delivery address',
		distance: 'distance text',
		location: 'location text',
		price: {
			toLabel: 'delivery price to label',
			tooltip: 'delivery price tooltip',
			value: 'delivery price value',
		},
	},
	documents: {
		seller: {
			gallery: 'seller documents gallery',
			image: 'seller document image',
		},
	},
	emailAddress: 'email address',
	navigation: {
		account: {
			menuHeader: 'account menu header',
		},
	},
	OTP: {
		description: 'OTP description',
	},
	payments: {
		brokerFee: 'broker fee',
		dealerAmount: 'financing amount',
		financeAmount: 'financing amount',
		financeSettlement: {
			gallery: 'gallery',
		},
		insufficientFunds: {
			accountName: 'account name',
			accountNumber: 'account number',
			amount: 'amount',
			balanceDifference: 'balance difference',
			sortCode: 'sort code',
		},
		mechanicalGuarantee: 'mechanical guarantee',
		paidDate: 'paid date',
		reference: 'payment reference',
		sellerAmount: 'seller amount',
		sellerDetails: 'seller payment details',
		totalVehiclePrice: 'total vehicle price',
		transportFee: 'broker fee',
		vehiclePrice: 'vehicle price',
		wallet: {
			content: 'wallet layout content',
			detailsModal: 'Vehicle details modal',
			fundsModal: 'funds modal',
			paidInOutModal: 'Paid in/out modal',
		},
		withdrawal: {
			accountNumber: 'account number',
			balance: 'balance',
			balanceDifference: 'balance difference',
			sortCode: 'sort code',
		},
	},
	phoneNumber: 'phone number',
	user: {
		initials: 'users initials',
		name: 'users name',
	},
	vehicle: {
		distanceFrom: 'distance from',
		serviceHistory: {
			gallery: 'service history gallery',
		},
		vrm: 'VRM',
	},
};
