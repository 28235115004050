export const PROTECTED_ROUTES = Object.freeze({
	ACCOUNT: '/account/[[...params]]',
	ACCOUNT_SETTINGS: '/account-settings',
	ACCOUNT_SETTINGS_ACCOUNT_REPORTING: '/account-settings/reporting',
	ACCOUNT_SETTINGS_COMPANY_INFORMATION: '/account-settings/company-information',
	BILLING: '/billing',
	CANCELLATION_AND_PRICE_ADJUSTMENTS: '/cancellation-and-price-adjustments',
	CODE_OF_CONDUCT: '/code-of-conduct',
	COMPANY_INFORMATION: '/account-settings/company-information//[[...slug]]',
	CONFIRM_IDENTITY: '/confirm-identity',
	DEALER_WALLER: '/wallet/[slug]',
	DELIVERY: '/delivery/[enquiryId]',
	INSIGHTS_LANDING: '/insights',
	INSIGHTS_RESULTS: '/insights/[make]/[model]',
	MANAGE_SAVED_SEARCH: '/manage/saved-search',
	MANAGE_SAVED_SEARCH_ADD: '/manage/saved-search/add',
	MANAGE_SAVED_SEARCH_CONFIRMATION: '/manage/saved-search/confirmation',
	MANAGE_SAVED_SEARCH_DELETE: '/manage/saved-search/delete',
	MODULR_PAYMENT_TERMS: '/modulr-payment-terms',
	PAYMENT_TERMS: '/payment-terms',
	PAYMENTS_ASSURANCE_POLICY: '/payments-assurance-policy',
	PRICING: '/pricing',
	SELLER_DETAILS: '/sellerdetails/[enquiryId]',
	TERMS: '/terms',
	VEHICLE_DETAILS: '/vehicle_details',
	VEHICLE_LIST: '/vehicle_list',
	VEHICLE_PAYMENT: '/vehiclepayment/[enquiryId]/[[...params]]',
	WELCOME: '/welcome',
	WITHDRAW_FUNDS: '/withdraw-funds/[[...params]]',
});

export const PUBLIC_ROUTES = Object.freeze({
	ACTIVATE_ACCOUNT: '/activate-account',
	CONTACT_US: '/contact-us',
	COOKIES: '/cookies',
	FORGOT_PASSWORD: '/forgot-password',
	HOME: '/home',
	HOW_IT_WORKS: '/',
	INDEX: '/',
	MOTORWAY_MOVE: '/motorway-move',
	MOTORWAY_PAY: '/motorway-pay',
	MOTORWAY_PAY_MODULR: '/motorway-pay-modulr',
	MOTORWAY_TRADE: '/motorway-trade',
	PRIVACY: '/privacy',
	RESET_PASSWORD: '/reset-password',
	SIGN_IN: '/signin',
	SIGN_UP: '/signup',
	STATIC_PAGE: '/static_page',
	TOKEN_EXPIRED: '/token-expired',
	TRADE: '/trade',
	UNSUPPORTED_BROWSER: '/unsupported-browser',
});

export const DEALER_FROZEN_DISALLOWED_ROUTES = Object.freeze({
	VEHICLE_DETAILS: '/vehicle_details',
	VEHICLE_LIST: '/vehicle_list',
});

const ROUTES = {
	ACCOUNT: {
		as(category, slug) {
			if (!category || !slug) {
				return ROUTES.ACCOUNT.href;
			}
			return `/account/${category}/${slug}`;
		},
		href: PROTECTED_ROUTES.ACCOUNT,
		isProtected: true,
	},
	ACCOUNT_SETTINGS: {
		as(slug) {
			if (!slug) {
				return ROUTES.ACCOUNT_SETTINGS.href;
			}
			return `/account-settings/${slug}`;
		},
		href: PROTECTED_ROUTES.ACCOUNT_SETTINGS,
		isProtected: true,
	},
	ACCOUNT_SETTINGS_ACCOUNT_REPORTING: {
		as() {
			return PROTECTED_ROUTES.ACCOUNT_SETTINGS_ACCOUNT_REPORTING;
		},
		href: PROTECTED_ROUTES.ACCOUNT_SETTINGS_ACCOUNT_REPORTING,
		isProtected: true,
	},
	ACCOUNT_SETTINGS_COMPANY_INFORMATION: {
		as() {
			return PROTECTED_ROUTES.ACCOUNT_SETTINGS_COMPANY_INFORMATION;
		},
		href: PROTECTED_ROUTES.ACCOUNT_SETTINGS_COMPANY_INFORMATION,
		isProtected: true,
	},
	ACTIVATE_ACCOUNT: {
		as() {
			return PUBLIC_ROUTES.ACTIVATE_ACCOUNT;
		},
		href: PUBLIC_ROUTES.ACTIVATE_ACCOUNT,
		isProtected: false,
	},
	BILLING: {
		as() {
			return ROUTES.BILLING.href;
		},
		href: PROTECTED_ROUTES.BILLING,
		isProtected: true,
	},
	CANCELLATION_AND_PRICE_ADJUSTMENTS: {
		as() {
			return ROUTES.CANCELLATION_AND_PRICE_ADJUSTMENTS.href;
		},
		href: PROTECTED_ROUTES.CANCELLATION_AND_PRICE_ADJUSTMENTS,
		isProtected: true,
	},
	CODE_OF_CONDUCT: {
		as() {
			return ROUTES.CODE_OF_CONDUCT.href;
		},
		href: PROTECTED_ROUTES.CODE_OF_CONDUCT,
		isProtected: true,
	},
	COMPANY_INFORMATION: {
		as(slug) {
			if (!slug) {
				return ROUTES.COMPANY_INFORMATION.href;
			}
			return `/account-settings/company-information/${slug}`;
		},
		href: PROTECTED_ROUTES.COMPANY_INFORMATION,
		isProtected: true,
	},
	CONFIRM_IDENTITY: {
		as() {
			return ROUTES.CONFIRM_IDENTITY.href;
		},
		href: PROTECTED_ROUTES.CONFIRM_IDENTITY,
		isProtected: true,
	},
	CONTACT_US: {
		as() {
			return ROUTES.CONTACT_US.href;
		},
		href: PUBLIC_ROUTES.CONTACT_US,
		isProtected: false,
	},
	COOKIES: {
		as() {
			return ROUTES.COOKIES.href;
		},
		href: PUBLIC_ROUTES.COOKIES,
		isProtected: false,
	},
	DEALER_WALLET: {
		as(slug) {
			if (!slug) {
				return ROUTES.DEALER_WALLET.href;
			}
			return `/wallet/${slug}`;
		},
		href: PROTECTED_ROUTES.DEALER_WALLER,
		isProtected: true,
	},
	DELIVERY: {
		as(enquiryId) {
			if (!enquiryId) {
				return ROUTES.DELIVERY.href;
			}
			return `/delivery/${enquiryId}`;
		},
		href: PROTECTED_ROUTES.DELIVERY,
		isProtected: true,
	},
	FORGOT_PASSWORD: {
		as() {
			return ROUTES.FORGOT_PASSWORD.href;
		},
		href: PUBLIC_ROUTES.FORGOT_PASSWORD,
		isProtected: false,
	},
	HOME: {
		as() {
			return ROUTES.HOME.href;
		},
		href: PUBLIC_ROUTES.HOME,
		isProtected: false,
	},
	HOW_IT_WORKS: {
		as() {
			return '/#howitworks';
		},
		href: PUBLIC_ROUTES.HOW_IT_WORKS,
		isProtected: false,
	},
	INDEX: {
		as() {
			return PUBLIC_ROUTES.INDEX;
		},
		href: PUBLIC_ROUTES.INDEX,
		isProtected: false,
	},
	INSIGHTS_LANDING: {
		as() {
			return ROUTES.INSIGHTS_LANDING.href;
		},
		href: PROTECTED_ROUTES.INSIGHTS_LANDING,
		isProtected: true,
	},
	INSIGHTS_RESULTS: {
		as(make, model) {
			if (!make || !model) {
				return ROUTES.INSIGHTS_RESULTS.href;
			}
			const encodedMake = encodeURIComponent(make);
			const encodedModel = encodeURIComponent(model);

			return `/insights/${encodedMake}/${encodedModel}`;
		},
		href: PROTECTED_ROUTES.INSIGHTS_RESULTS,
		isProtected: true,
	},
	MANAGE_SAVED_SEARCH: {
		as() {
			return ROUTES.MANAGE_SAVED_SEARCH.href;
		},
		href: PROTECTED_ROUTES.MANAGE_SAVED_SEARCH,
		isProtected: true,
	},
	MANAGE_SAVED_SEARCH_ADD: {
		as() {
			return ROUTES.MANAGE_SAVED_SEARCH_ADD.href;
		},
		href: PROTECTED_ROUTES.MANAGE_SAVED_SEARCH_ADD,
		isProtected: true,
	},
	MANAGE_SAVED_SEARCH_CONFIRMATION: {
		as() {
			return ROUTES.MANAGE_SAVED_SEARCH_CONFIRMATION.href;
		},
		href: PROTECTED_ROUTES.MANAGE_SAVED_SEARCH_CONFIRMATION,
		isProtected: true,
	},
	MANAGE_SAVED_SEARCH_DELETE: {
		as() {
			return ROUTES.MANAGE_SAVED_SEARCH_DELETE.href;
		},
		href: PROTECTED_ROUTES.MANAGE_SAVED_SEARCH_DELETE,
		isProtected: true,
	},
	MODULR_PAYMENT_TERMS: {
		as() {
			return ROUTES.MODULR_PAYMENT_TERMS.href;
		},
		href: PROTECTED_ROUTES.MODULR_PAYMENT_TERMS,
		isProtected: true,
	},
	MOTORWAY_MOVE: {
		as() {
			return PUBLIC_ROUTES.MOTORWAY_MOVE;
		},
		href: PUBLIC_ROUTES.MOTORWAY_MOVE,
		isProtected: false,
	},
	MOTORWAY_PAY: {
		as() {
			return PUBLIC_ROUTES.MOTORWAY_PAY;
		},
		href: PUBLIC_ROUTES.MOTORWAY_PAY,
		isProtected: false,
	},
	MOTORWAY_PAY_MODULR: {
		as() {
			return PUBLIC_ROUTES.MOTORWAY_PAY_MODULR;
		},
		href: PUBLIC_ROUTES.MOTORWAY_PAY_MODULR,
		isProtected: false,
	},
	MOTORWAY_TRADE: {
		as() {
			return PUBLIC_ROUTES.MOTORWAY_TRADE;
		},
		href: PUBLIC_ROUTES.MOTORWAY_TRADE,
		isProtected: false,
	},
	PAYMENT_TERMS: {
		as() {
			return ROUTES.PAYMENT_TERMS.href;
		},
		href: PROTECTED_ROUTES.PAYMENT_TERMS,
		isProtected: true,
	},
	PAYMENTS_ASSURANCE_POLICY: {
		as() {
			return ROUTES.PAYMENTS_ASSURANCE_POLICY.href;
		},
		href: PROTECTED_ROUTES.PAYMENTS_ASSURANCE_POLICY,
		isProtected: true,
	},
	PRICING: {
		as() {
			return ROUTES.PRICING.href;
		},
		href: PROTECTED_ROUTES.PRICING,
		isProtected: true,
	},
	PRIVACY: {
		as() {
			return ROUTES.PRIVACY.href;
		},
		href: PUBLIC_ROUTES.PRIVACY,
		isProtected: false,
	},
	RESET_PASSWORD: {
		as() {
			return PUBLIC_ROUTES.RESET_PASSWORD;
		},
		href: PUBLIC_ROUTES.RESET_PASSWORD,
		isProtected: false,
	},
	SELLER_DETAILS: {
		as(enquiryId) {
			if (!enquiryId) {
				return ROUTES.SELLER_DETAILS.href;
			}
			return `/sellerdetails/${enquiryId}`;
		},
		href: PROTECTED_ROUTES.SELLER_DETAILS,
		isProtected: true,
	},
	SIGN_IN: {
		as() {
			return ROUTES.SIGN_IN.href;
		},
		href: PUBLIC_ROUTES.SIGN_IN,
		isProtected: false,
	},
	SIGN_UP: {
		as() {
			return PUBLIC_ROUTES.SIGN_UP;
		},
		href: PUBLIC_ROUTES.SIGN_UP,
		isProtected: false,
	},
	STATIC_PAGE: {
		as() {
			return ROUTES.STATIC_PAGE.href;
		},
		href: PUBLIC_ROUTES.STATIC_PAGE,
		isProtected: false,
	},
	TERMS: {
		as() {
			return ROUTES.TERMS.href;
		},
		href: PROTECTED_ROUTES.TERMS,
		isProtected: true,
	},
	TOKEN_EXPIRED: {
		as() {
			return ROUTES.TOKEN_EXPIRED.href;
		},
		href: PUBLIC_ROUTES.TOKEN_EXPIRED,
		isProtected: false,
	},
	TRADE: {
		as() {
			return PUBLIC_ROUTES.TRADE;
		},
		href: PUBLIC_ROUTES.TRADE,
		isProtected: false,
	},
	UNSUPPORTED_BROWSER: {
		as() {
			return ROUTES.UNSUPPORTED_BROWSER.href;
		},
		href: PUBLIC_ROUTES.UNSUPPORTED_BROWSER,
		isProtected: false,
	},
	VEHICLE_DETAILS: {
		as(enquiryId) {
			if (!enquiryId) {
				return ROUTES.VEHICLE_DETAILS.href;
			}
			return `/vehicles/${enquiryId}`;
		},
		href: PROTECTED_ROUTES.VEHICLE_DETAILS,
		isProtected: true,
	},
	VEHICLE_LIST: {
		as() {
			return '/vehicles';
		},
		href: PROTECTED_ROUTES.VEHICLE_LIST,
		isProtected: true,
	},
	VEHICLE_PAYMENT: {
		as(enquiryId) {
			if (!enquiryId) {
				return ROUTES.VEHICLE_PAYMENT.href;
			}
			return `/vehiclepayment/${enquiryId}`;
		},
		href: PROTECTED_ROUTES.VEHICLE_PAYMENT,
		isProtected: true,
	},
	WELCOME: {
		as() {
			return PROTECTED_ROUTES.WELCOME;
		},
		href: PROTECTED_ROUTES.WELCOME,
		/**
		 * This is a protected page. The welcome page requires a page reload to fetch newly created and signed in user.
		 * If isProtected is set to true, the FE middleware will redirect to the sign-in page before the welcome
		 * is able to render with the new user. Keep this set to false. The route is still protected server-side.
		 */
		isProtected: false,
	},
	WITHDRAW_FUNDS: {
		as(withdrawalId) {
			if (!withdrawalId) {
				return '/withdraw-funds';
			}

			return `/withdraw-funds/${withdrawalId}`;
		},
		href: PROTECTED_ROUTES.WITHDRAW_FUNDS,
		isProtected: true,
	},
};

export const IGNORED_FROM_HISTORY_ROUTES = [
	ROUTES.MANAGE_SAVED_SEARCH.href,
	ROUTES.MANAGE_SAVED_SEARCH_ADD.href,
	ROUTES.MANAGE_SAVED_SEARCH_CONFIRMATION.href,
	ROUTES.MANAGE_SAVED_SEARCH_DELETE.href,
];

export const SCROLL_TOP_USING_AS_PATH_CHANGE_ROUTES = [ROUTES.VEHICLE_PAYMENT.href, ROUTES.WITHDRAW_FUNDS.href];

export const API_ROUTES = {
	ACTIVATE_DEALER: '/api/user/request-dealer-activation',
	AUCTION_UNSUBSCRIBE: '/api/alerts/auction-unsubscribe',
	BIDDING_HISTORY_BIDS_ACTIVE: '/api/account/bidding-history/bids/active',
	BIDDING_HISTORY_BIDS_DIDNT_WIN: '/api/account/bidding-history/bids/didnt-win',
	BIDDING_HISTORY_BIDS_UNDER_OFFER: '/api/account/bidding-history/bids/under-offer',
	BIDDING_HISTORY_COUNTS: '/api/account/bidding-history/counts',
	BIDDING_HISTORY_PURCHASES_CANCELLED: '/api/account/bidding-history/purchases/cancelled',
	BIDDING_HISTORY_PURCHASES_COMPLETE: '/api/account/bidding-history/purchases/complete',
	BIDDING_HISTORY_STATUS_COUNTS: '/api/account/bidding-history/purchases-complete-statuses-count',
	BIDS: '/api/bids',
	BIDS_CANCEL: '/api/cancel_bid/:id',
	BRAZE_AUTH: '/api/braze',
	CHECK_PERMISSIONS: '/api/check-permissions',
	FEATURE_FLAGS: '/api/feature-flags',
	FEES: '/api/fees',
	FEES_CALCULATE: '/api/fees/calculate',
	GENERATE_PURCHASES_CSV: '/api/generate_purchases_csv',
	GET_PURCHASES_CSV: '/api/get_purchases_csv',
	INSIGHTS_VEHICLES_RECENT_SOLD: '/api/insights/vehicles/recent-sold',
	INSTANT_MATCH: '/api/retail-prices',
	INVOICED_MAGIC_LINK: '/api/invoiced-magic-login-link/:dealerId',
	KYC: '/api/kyc',
	LIGHTDASH_REPORT: '/api/lightdash/report',
	LOGIN: '/api/login',
	LOGOUT: '/api/logout',
	MAKE_MODEL_COUNT: '/api/make-model-count',
	MAKES: '/api/makes',
	MODELS: '/api/models',
	PAYMENTS: '/api/payments',
	PDF: '/api/pdf',
	REQUEST_RESET_PASSWORD: '/api/user/request-reset-password',
	SALE: '/api/sale',
	SAVED_SEARCHES: '/api/saved-filters',
	SAVED_SEARCHES_CREATE: '/api/saved-filters/create',
	SAVED_SEARCHES_DELETE: '/api/saved-filters/:id/delete',
	SAVED_SEARCHES_UPDATE: '/api/saved-filters/:id/update',
	SELLER_DETAILS: '/api/sellerdetails',
	SELLER_DOCUMENTS: '/api/sellerdocuments',
	SET_PASSWORD: '/api/user/set-password',
	SHORTLIST: '/api/shortlisted-enquiries/:enquiryId/shortlist',
	SHORTLISTED_ENQUIRIES: '/api/shortlisted-enquiries',
	SIGN_UP: '/api/signup',
	SIMILAR_VEHICLES: '/api/vehicle/similarVehicles',
	SIMILAR_VEHICLES_BY_MAKE_AND_MODEL: '/api/vehicle/similarVehiclesByMakeAndModel',
	TRANSPORT: '/api/delivery/:enquiryId/transport',
	TRANSPORT_OPTIONS: '/api/delivery/:enquiryId/transport-options',
	UNSHORTLIST: '/api/shortlisted-enquiries/:enquiryId/unshortlist',
	UNSHORTLIST_ENQUIRIES: '/api/shortlisted-enquiries/unshortlist-enquiries',
	VEHICLE: '/api/vehicle',
	VEHICLE_LIST: '/api/vehicle/list',
	WITHDRAWALS: '/api/withdrawals',
};

export const EXTERNAL_ROUTES = {
	ADJUSTMENTS_AND_CANCELLATIONS_ZENDESK: 'https://help.dealers.motorway.co.uk/hc/en-gb/requests/new',
};

export default ROUTES;
