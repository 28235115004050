export const RESPONSE_STATUS = Object.freeze({
	client: {
		forbidden: 403,
		notFound: 404,
		unauthorized: 401,
		unprocessableEntity: 422,
	},
});

export const ClientError = (() => ({
	isForbidden(status) {
		return status === RESPONSE_STATUS.client.forbidden;
	},
	isNotFound(status) {
		return status === RESPONSE_STATUS.client.notFound;
	},
	isUnauthorized(status) {
		return status === RESPONSE_STATUS.client.unauthorized;
	},
}))();
