import { SessionUser } from 'Types/auth';

export type UserIdentityForTracking = Pick<SessionUser, 'dealerId' | 'id'> & { dealerStatus: string; features: string };

const getUserIdentityForTracking = (user: SessionUser): UserIdentityForTracking => {
	// We want to limit the information we track about users, to comply with GDPR
	const { dealerId, dealerStatus, features, id } = user || {};
	return { dealerId, dealerStatus: JSON.stringify(dealerStatus), features: JSON.stringify(features), id };
};

export default getUserIdentityForTracking;
