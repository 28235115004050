import { GA_VEHICLE_DETAILS_CATEGORIES } from 'Components/pages/vehicleDetails/VehicleDetails.helpers';

import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;
const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

export const triggerHeroGalleryClosed = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		name: 'dlr_hero_gallery_closed',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Hero gallery closed',
			category: GA_VEHICLE_DETAILS_CATEGORIES.GALLERY,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerHeroGalleryNextClicked = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		name: 'dlr_hero_gallery_next_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Hero gallery next button clicked',
			category: GA_VEHICLE_DETAILS_CATEGORIES.GALLERY,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerHeroGalleryOpen = ({ imageKind }: { imageKind: string }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		customData: { label: imageKind },
		name: 'dlr_hero_gallery_open',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Open',
			category: GA_VEHICLE_DETAILS_CATEGORIES.GALLERY,
			isLegacyEvent: false,
			label: imageKind,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerHeroGalleryPrevClicked = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		name: 'dlr_hero_gallery_prev_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Hero gallery prev button clicked',
			category: GA_VEHICLE_DETAILS_CATEGORIES.GALLERY,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerHeroGalleryImageViewed = ({ imageKind }: { imageKind: string }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		customData: { label: imageKind },
		name: 'dlr_hero_gallery_image_viewed',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Hero gallery image viewed',
			category: GA_VEHICLE_DETAILS_CATEGORIES.GALLERY,
			isLegacyEvent: false,
			label: imageKind,
		},
		provider: GOOGLE_ANALYTICS,
	});
};
