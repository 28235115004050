import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

const {
	MARKETPLACE: { DOWNLOADABLE_VEHICLE_LIST },
} = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerDownloadCSVButtonClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: DOWNLOADABLE_VEHICLE_LIST,
		name: 'dlr_mrktplace_download_vehicle_list_CSV_Button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	const gaEventPayload = {
		action: 'Download CSV button clicked',
		category: DOWNLOADABLE_VEHICLE_LIST,
		isLegacyEvent: false,
		label: '',
	};

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};

export const triggerDownloadCSVButtonHoveredEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: DOWNLOADABLE_VEHICLE_LIST,
		name: 'dlr_mrktplace_download_vehicle_list_CSV_Button_hovered',
		schemaName: SNOWPLOW_EVENTS.HOVER.SCHEMA,
		version: SNOWPLOW_EVENTS.HOVER.VERSION,
	});

	const gaEventPayload = {
		action: 'Download CSV button hover',
		category: DOWNLOADABLE_VEHICLE_LIST,
		isLegacyEvent: false,
		label: '',
	};

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};
