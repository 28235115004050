import { SellerStats, SellerStatsFromApi } from 'Types/seller';
import dayjs from 'Utilities/dayjs';

export const DEFAULT_TRADE_DEALER_STATS = {
	dealerActiveYears: 2,
	dealerCompanyActiveYears: 3,
	numberOfDealerTransactions: 50,
};

const {
	dealerActiveYears: defaultDealerActiveYears,
	dealerCompanyActiveYears: defaultDealerCompanyActiveYears,
	numberOfDealerTransactions: defaultNumberOfDealerTransactions,
} = DEFAULT_TRADE_DEALER_STATS;

const getYearDiff = (date: string) => dayjs().diff(date, 'year');

export const transformSellerStats = (sellerStats: SellerStatsFromApi): SellerStats => {
	const { dealerBusinessCreatedOn, dealerCreatedOn, numberOfDealerTransactions: dealerTransactionsCount } = sellerStats;

	const dealerActiveYears = dealerCreatedOn ? getYearDiff(dealerCreatedOn) : defaultDealerActiveYears;
	const dealerCompanyActiveYears = dealerBusinessCreatedOn
		? getYearDiff(dealerBusinessCreatedOn)
		: defaultDealerCompanyActiveYears;

	const numberOfTransactions = dealerTransactionsCount ?? defaultNumberOfDealerTransactions;

	const numberOfDealerTransactions = Math.floor(numberOfTransactions / 5) * 5;

	return {
		dealerActiveYears,
		dealerCompanyActiveYears,
		numberOfDealerTransactions,
	};
};
