import Cookies from 'js-cookie';

import { BRAZE_API, BRAZE_SDK_ENDPOINT } from 'Services/braze/braze.const';
import { stringBooleanToBoolean } from 'Utilities/helpers';

import { modifySlideUpMessage, sdkAuthenticationSubscriber } from './braze.helpers';
import { reportBrazeInitialisationFailureError } from './brazeErrorHandling';

const SMART_VEHICLE_SUGGESTIONS_HEADER = 'New! Smart vehicle suggestions';

export const onInitialisationSuccess = async (signedInUserId: string): Promise<void> => {
	const { showInAppMessage, SlideUpMessage, subscribeToInAppMessage, subscribeToSdkAuthenticationFailures } =
		await import('Services/braze/brazeExports');

	subscribeToSdkAuthenticationFailures(
		async (error): Promise<void> => sdkAuthenticationSubscriber(error, signedInUserId),
	);

	subscribeToInAppMessage(async (inAppMessage) => {
		try {
			const isModifySmartVehicleSuggestionsBrazeMessageEnabled = stringBooleanToBoolean(
				Cookies.get('modifySmartVehicleSuggestionsBrazeMessage'),
			);

			const shouldModifySmartVehicleSuggestionsBrazeMessage =
				inAppMessage instanceof SlideUpMessage &&
				inAppMessage.message?.includes(SMART_VEHICLE_SUGGESTIONS_HEADER) &&
				isModifySmartVehicleSuggestionsBrazeMessageEnabled;

			if (shouldModifySmartVehicleSuggestionsBrazeMessage) {
				const modifiedSmartVehicleSuggestionsMessage = await modifySlideUpMessage({
					modifications: {
						css: '#smartVehicleSuggestionsBrazeSlideup  { div:first-child { max-height: unset; color: #FFFFFF; background-color: #2D2D2B; span { font-weight: 400; line-height: normal; max-height: fit-content; } span:first-line { font-weight: bold; } button { display: unset; svg:first-child { display: unset; } } } }',
						htmlId: 'smartVehicleSuggestionsBrazeSlideup',
					},
					originalMessage: inAppMessage,
				});

				showInAppMessage(modifiedSmartVehicleSuggestionsMessage);
			} else {
				showInAppMessage(inAppMessage);
			}
		} catch (err) {
			showInAppMessage(inAppMessage);
		}
	});
};

export const onInitialisationFailure = (signedInUserId: string): void => {
	const initialisationError = {
		brazeAPI: BRAZE_API,
		brazeSDKEndpoint: BRAZE_SDK_ENDPOINT,
		userId: signedInUserId,
	};

	reportBrazeInitialisationFailureError(initialisationError);
};
